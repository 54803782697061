var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_ar_scheme_scheme d-flex flex-column align-center" },
    [
      _c("div", { staticClass: "img_group flex-shrink-0" }, [
        _c("div", {
          staticClass: "img",
          style: {
            backgroundImage: `url(${_vm.$file_url}${_vm.scheme.pic})`,
          },
        }),
      ]),
      _c("div", { staticClass: "info_group bbox" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.scheme.name) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "fun_group d-flex align-center justify-end" },
          [
            _vm.showDelete
              ? _c(
                  "div",
                  {
                    staticClass: "btn del",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openDeleteDialog.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        删除\n      ")]
                )
              : _vm._e(),
            _vm.showCover
              ? _c(
                  "div",
                  {
                    staticClass: "btn cover",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openCoverHandler.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        封面\n      ")]
                )
              : _vm._e(),
            _vm.showOffline
              ? _c(
                  "div",
                  {
                    staticClass: "btn offline",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openPublishHanler({
                          publish: 0,
                        })
                      },
                    },
                  },
                  [_vm._v("\n        下线\n      ")]
                )
              : _vm._e(),
            _vm.showPublish
              ? _c(
                  "div",
                  {
                    staticClass: "btn publish",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openPublishHanler({
                          publish: 1,
                        })
                      },
                    },
                  },
                  [_vm._v("\n        发布\n      ")]
                )
              : _vm._e(),
            _vm.showEdit
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn_dark edit",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchEdit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        编辑\n      ")]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }