var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_ar_scheme_dialog_publish",
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "operation_group flex-shrink-0 d-flex align-center bbox justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "btn btn_dark sure",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.sureHandler.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      确认\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "btn btn_light cancel",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeHandler.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      取消\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hint_group" }, [
      _c("div", { staticClass: "hint" }, [
        _vm._v("\n      删除后, 此方案将从草稿箱中永久去除\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }