<script>
import ARScheme from '@/classes/modules/AR/ARScheme'
import ARScene from '@/classes/modules/AR/ARScene'

export default {
  props: {
    scheme: {
      type: ARScheme,
      default: undefined
    },
    scene: {
      type: ARScene,
      default: undefined
    }
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    closeHandler () {
      // this.$modal.hide('')
      this.$emit('closeHandler')
    },
    async sureHandler () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await this.scheme.delete()
      if (res.message === 'success') {
        const index = this.scene.planList.findIndex((unit) => {
          return Number(unit.id) === Number(this.scheme.id)
        })
        if (index !== -1) {
          this.scene.planList.splice(index, 1)
          this.closeHandler()
          this.$notice({
            desc: '删除成功',
            type: 'success'
          })
        }
      }
    }

  }
}
</script>

<template>
  <div
    v-loading="loading"
    class="v_ar_scheme_dialog_publish"
  >
    <div
      class="hint_group"
    >
      <div
        class="hint"
      >
        删除后, 此方案将从草稿箱中永久去除
      </div>
    </div>
    <div
      class="operation_group flex-shrink-0 d-flex align-center bbox justify-center"
    >
      <div
        class="btn btn_dark sure"
        @click.stop="sureHandler"
      >
        确认
      </div>
      <div
        class="btn btn_light cancel"
        @click.stop="closeHandler"
      >
        取消
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v_ar_scheme_dialog_publish {
  .operation_group {
    width: 100%;
    font-size: 14px;
    border-top: 1px solid #f8f8f8;
    padding: 10px 30px;
    .btn{
      cursor: pointer;
      line-height: 36px;
      padding: 0 30px;
      text-align: center;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
  .hint_group {
    padding: 20px;
    .hint {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>