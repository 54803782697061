<script>
import ARScheme from '@/classes/modules/AR/ARScheme'
import ARScene from '@/classes/modules/AR/ARScene'
import store from '@/store'
export default {
  props: {
    scheme: {
      type: ARScheme,
      default: undefined
    },
    scene: {
      type: ARScene,
      default: undefined
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showCover: {
      type: Boolean,
      default: true
    },
    showOffline: {
      type: Boolean,
      default: true
    },
    showPublish: {
      type: Boolean,
      default: true
    },
    showEdit: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    openCoverHandler () {
      this.$emit(
        'coverHandler',
        {
          scheme: this.scheme,
          scene: this.scene
        }
      )
    },
    openPublishHanler (params) {
      this.$emit(
        'publishHandler',
        {
          scheme: this.scheme,
          scene: this.scene,
          publish: params.publish
        }
      )
    },
    openDeleteDialog () {
      this.$emit(
        'deleteHandler',
        {
          scheme: this.scheme,
          scene: this.scene
        }
      )
    },
    touchEdit () {
      store.commit(
        'setArEditSceneData',
        {
          scene: this.scene,
          scheme: this.scheme,
          save: true,
        }
      )
      store.commit('setArPageType', 'asset')
      this.$router.push({
        name: 'AREdit',
        params: {
          id: this.scheme.id
        }
      })
    },
  }

}
</script>

<template>
<div
  class="v_ar_scheme_scheme d-flex flex-column align-center"
>
  <div
    class="img_group flex-shrink-0"
  >
    <div
      class="img"
      :style="{
        backgroundImage: `url(${ $file_url }${ scheme.pic })`
      }"
    ></div>
  </div>
  <div
    class="info_group bbox"
  >
    <div
      class="name"
    >
      {{ scheme.name }}
    </div>
    <div
      class="fun_group d-flex align-center justify-end"
    >
      <div
        v-if="showDelete"
        class="btn del"
        @click.stop="openDeleteDialog"
      >
        删除
      </div>
      <div
        v-if="showCover"
        class="btn cover"
        @click.stop="openCoverHandler"
      >
        封面
      </div>
      <div
        v-if="showOffline"
        class="btn offline"
        @click.stop="openPublishHanler({
          publish: 0
        })"
      >
        下线
      </div>
      <div
        v-if="showPublish"
        class="btn publish"
        @click.stop="openPublishHanler({
          publish: 1
        })"
      >
        发布
      </div>
      <div
        v-if="showEdit"
        class="btn btn_dark edit"
        @click.stop="touchEdit"
      >
        编辑
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_ar_scheme_scheme {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border-radius: 4px;
  border: 1px solid #E8EEFF;
  color: #333;
  .img_group {
    width: 100%;
    height: 158px;
    .img {
      @include background;
      width: 100%;
      height: 100%;
    }
  }
  .info_group {
    width: 100%;
    height: 100%;
    padding: 16px;
    .name {
      @include single_line_intercept;
      font-size: 16px;
      width: 100%;
    }
    .fun_group {
      margin-top: 10px;
      .btn {
        cursor: pointer;
        box-sizing: border-box;
        margin: {
          left: 8px;
        }
        border: 1px solid #D7D7D7;
        width: 52px;
        color: #333;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        border-radius: 4px;
        &.edit {
          color: #fff;
          border-color: $main_blue;
        }
        &.offline {
          &:hover {
            color: #CD5252;
            border-color: #EEB2B2;
          }
        }
        &.cover {
          &:hover {
            color: $main_blue;
            border-color: $main_blue;
          }
        }
        &.del {
          &:hover {
            color: #CD5252;
            border-color: #EEB2B2;
          }
        }
        &.publish {
          color: #fff;
          background-color: #399786;
          border-color: #399786;
          &:hover {
            background-color: #2C796B;
            border-color: #2C796B;
          }
        }
      }
    }
  }
}
</style>
